import React, { useEffect, useState } from "react";
import {
  TextField,
  Switch,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import Delete_new from "../../../assets/fonts/Delete_new.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import Select from "react-select";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import { toast } from "react-toastify";
import dayjs from 'dayjs';

const AccordionContent = ({ workEntries, cardId, userId, timeTypes, groups, costCodes, billingTos, classifications, lunchIntervals, viewWorkEntry}) => {

  let [items, setItems] = useState([
    {
      id: Date.now(),
      classification_id: "",
      cost_code_id: "",
      time_type_id: "",
      start_time: "",
      end_time: "",
      group_id: "",
      lunch_time: "",
      comments: "",
      is_billable: false,
      billing_to_id: "",
      user_id: userId.toString(),
      work_entry_id: "",
      on_submit: false,
      from_web: true
    },
  ]);

  const addItems = () => {
    setItems([...items, ...[{
      id: items.length + 1,
      on_submit: false,
      work_entry_id: "",
      user_id: userId.toString()
    }]]);
  };

  const removeItem = (id, index) => {
    if(items[index]['work_entry_id'] ) {
      handleDelete(id, index)
    }
    else {
      let final = items.filter((val) => {
        return val.id != id;
      });
      setItems(final);
    }
  };

  const handleTimeChange = (val, name, index) => {
    const formattedTime = moment(new Date(val)).format("hh:mm A");
    items[index][name] = formattedTime.toString();
    setItems([...items]);
  };

  const handleChange = (val, name, index) => {
    items[index][name] = name !== 'is_billable' ? val.toString() : val;
    setItems([...items]);
  };

  const handleSave = async (index) => {
    try {

      if ( !items[index] ||
          !items[index].classification_id ||
          !items[index].cost_code_id ||
          !items[index].start_time ||
          !items[index].end_time ||
          !items[index].group_id) {
        items[index]['on_submit'] = true
        setItems([...items]);
        return false;
      }
      await axios
          .post(`${Application.api_url}/api/v2/time_cards/${cardId}/work_entries`, items[index])
          .then((res) => {
            // setLoading(false);
            if(res.status === 200) {
              items[index]['work_entry_id'] = res.data.data.id
              setItems([...items]);
              toast.success('Created Successfully');
            }
            return true
          })
          .catch((err) => {
            if (err.response.status === 422) {
              toast.error(err.response.data.message[0]);
            }
            console.log("err=>", err)
          });
    } catch (err) {
      console.log("err===>", err);
    }
  };

  const handleUpdate = async (index) => {
    try {
      if ( !items[index] ||
          !items[index].classification_id ||
          !items[index].cost_code_id ||
          !items[index].start_time ||
          !items[index].end_time ||
          !items[index].group_id) {
        items[index]['on_submit'] = true
        setItems([...items]);
        return false;
      }

      await axios
          .patch(`${Application.api_url}/api/v2/time_cards/${cardId}/work_entries/${items[index]['work_entry_id']}`, items[index])
          .then((res) => {
            // setLoading(false);
            if(res.status === 200) {
              toast.success('Update Successfully');
            }
            return true
          })
          .catch((err) => {
            if (err.response.status === 422) {
              toast.error(err.response.data.message[0]);
            }
            console.log("err=>", err)
          });
    } catch (err) {
      console.log("err===>", err);
    }
  };

  const handleDelete = async (id, index) => {
    try {
      await axios
          .delete(`${Application.api_url}/api/v2/time_cards/${cardId}/work_entries/${items[index]['work_entry_id']}`)
          .then((res) => {
            // setLoading(false);
            if(res.status === 200) {
              let final = items.filter((val) => {
                return val.id != id;
              });
              setItems(final);
              toast.success('Deleted Successfully');
            }
            return true
          })
          .catch((err) => {
            if (err.response.status === 422) {
              toast.error(err.response.data.message[0]);
            }
            console.log("err=>", err)
          });
    } catch (err) {
      console.log("err===>", err);
    }
  };

  useEffect(() => {
    if(workEntries.length > 0) {
      let workEntryItems = workEntries.filter((value) => value.user_id == userId)
      workEntryItems.length > 0 && setItems(workEntryItems)
    }
  }, [workEntries])

  return (
      <>
        <div className="contents-item">
          {items.map((val, index) => (
              <div className="items-details" key={index}>
                <div className="item-name">
                  <h4>Untitled Entry {val.id}</h4>
                  {!viewWorkEntry &&
                      <img
                          src={Delete_new}
                          alt="Delete_new"
                          onClick={() => removeItem(val.id, index)}
                      />
                  }
                </div>
                <div className="body-card">
                  <div className="row">
                    <div className="col-md-12" style={{marginBottom: '20px'}}>
                      <div className="row">
                        <div className="col-md-3">
                          <label className="field-lable">
                            Classification <text style={{ color: "red" }}>*</text>
                          </label>
                          <Select
                              className="tt-dropdown-select"
                              options={classifications}
                              isDisabled={viewWorkEntry}
                              placeholder="Select Classification"
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              value={classifications.filter((val) => val.id == items[index]['classification_id'])}
                              onChange={(event, value) => {
                                handleChange(event.id, 'classification_id', index)
                              }}
                          />
                          {items[index]['on_submit'] && !items[index]['classification_id'] && (
                              <p className="required-fields mt-1">
                                Please select the Classifications
                              </p>
                          )}
                        </div>
                        <div className="col-md-3">
                          <label className="field-lable">
                            Cost Code<text style={{ color: "red" }}>*</text>
                          </label>
                          <Select
                              className="tt-dropdown-select"
                              options={costCodes}
                              isDisabled={viewWorkEntry}
                              placeholder="Select Code Code"
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              value={costCodes.filter((val) => val.id == items[index]['cost_code_id'])}
                              onChange={(event, value) => {
                                handleChange(event.id, 'cost_code_id', index)
                              }}
                          />
                          {items[index]['on_submit'] && !items[index]['cost_code_id'] && (
                              <p className="required-fields mt-1">
                                Please select the Cost Code
                              </p>
                          )}
                        </div>
                        <div className="col-md-2">
                          <label className="field-lable">Time Type</label>
                          <Select
                              className="tt-dropdown-select"
                              options={timeTypes}
                              isDisabled={viewWorkEntry}
                              placeholder="Select Time Type"
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              value={timeTypes.filter((val) => val.id == items[index]['time_type_id'])}
                              onChange={(event, value) => {
                                handleChange(event.id, 'time_type_id', index)
                              }}
                          />
                        </div>
                        <div className="col-md-2">
                          <label className="field-lable">
                            Start Time <text style={{ color: "red" }}>*</text>
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["TimePicker"]}>
                              <TimePicker
                                  disabled={viewWorkEntry}
                                  className="time-picker-new"
                                  onChange={(newValue) =>
                                      handleTimeChange(newValue, "start_time", index)
                                  }
                                  value={dayjs(items[index]['start_time'], 'hh:mm A').isValid() ? dayjs(items[index]['start_time'], 'hh:mm A') : null}
                                  inputFormat="hh:mm A"
                                  name="start_time"
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          {items[index]['on_submit'] && !items[index]['start_time'] && (
                              <p className="required-fields mt-1">
                                Please select the Start Time
                              </p>
                          )}
                        </div>
                        <div className="col-md-2">
                          <label className="field-lable">
                            Stop Time <text style={{ color: "red" }}>*</text>
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["TimePicker"]}>
                              <TimePicker
                                  disabled={viewWorkEntry}
                                  className="time-picker-new"
                                  onChange={(newValue) =>
                                      handleTimeChange(newValue, "end_time", index)
                                  }
                                  value={dayjs(items[index]['end_time'], 'hh:mm A').isValid() ? dayjs(items[index]['end_time'], 'hh:mm A') : null}
                                  inputFormat="hh:mm A"
                                  name="end_time"
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          {items[index]['on_submit'] && !items[index]['end_time'] && (
                              <p className="required-fields mt-1">
                                Please select the End Time
                              </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-2">
                          <label className="field-lable">Lunch Time </label>
                          <Select
                              className="tt-dropdown-select"
                              options={lunchIntervals}
                              isDisabled={viewWorkEntry}
                              placeholder="Select Lunch Time"
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              value={lunchIntervals.filter((val) => val.name == items[index]['lunch_time'])}
                              onChange={(event, value) => {
                                handleChange(event.name, 'lunch_time', index)
                              }}
                          />
                        </div>
                        <div className="col-md-2">
                          <label className="field-lable">
                            Group <text style={{color: "red"}}>*</text>
                          </label>
                          <Select
                              className="tt-dropdown-select"
                              options={groups}
                              isDisabled={viewWorkEntry}
                              placeholder="Select Group"
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              value={groups.filter((val) => val.id == items[index]['group_id'])}
                              onChange={(event, value) => {
                                handleChange(event.id, 'group_id', index)
                              }}
                          />
                          {items[index]['on_submit'] && !items[index]['group_id'] && (
                              <p className="required-fields mt-1">
                                Please select the Group
                              </p>
                          )}
                        </div>
                        <div className="col-md-2">
                          <div className="set-notification-alert">
                            <div className="first-col">
                              <Switch
                                  disabled={viewWorkEntry}
                                  checked={!items[index].is_billable}
                                  onClick={(event) =>
                                      handleChange(!items[index].is_billable, 'is_billable', index)
                                  }
                              />
                              <span className="text-alert">Is Billable</span>
                            </div>
                          </div>
                        </div>
                        {!items[index].is_billable && (
                            <div className="col-md-2">
                              <label className="field-lable">
                                Billing To <text style={{ color: "red" }}>*</text>
                              </label>
                              <Select
                                  className="tt-dropdown-select"
                                  options={billingTos}
                                  isDisabled={viewWorkEntry}
                                  placeholder="Select Group"
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  value={billingTos.filter((val) => val.id == items[index]['billing_to_id'])}
                                  onChange={(event, value) => {
                                    handleChange(event.id, 'billing_to_id', index)
                                  }}
                              />
                              {items[index]['on_submit'] && !items[index]['billing_to_id'] && (
                                  <p className="required-fields mt-1">
                                    Please select the Billing To
                                  </p>
                              )}
                            </div>
                        )}
                        <div
                            className={
                              !items[index].is_billable ? "col-md-4" : "col-md-6"
                            }
                        >
                          <label className="field-lable">Description</label>
                          <textarea
                              className="create-asset-textarea"
                              placeholder="Enter Description"
                              aria-label="empty textarea"
                              value={items[index]['comments']}
                              disabled={viewWorkEntry}
                              onChange={(e) => {
                                handleChange(e.target.value, 'comments', index)
                              }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {!viewWorkEntry &&
                      <div className="save-work-entry">
                        <Button
                            className="btn-create"
                            variant="contained"
                            onClick={() => {
                              {
                                items[index]['work_entry_id'] ? handleUpdate(index) : handleSave(index)
                              }
                            }}
                        >
                          {items[index]['work_entry_id'] ? 'Update' : 'Save'}
                        </Button>
                      </div>
                  }
                </div>
              </div>
          ))}
        </div>
        {!viewWorkEntry &&
            <div className="footer">
              <div className="add-work-entries" onClick={() => addItems()}>
                <span>+</span>
                <span className="title">Add Work Entry</span>
              </div>
              <div>

              </div>
            </div>
        }
      </>
  );
};

export default AccordionContent;
